<template>
	<section class="sec-servicios-detalles container-fluid">
		<div class="row mb-4">
			<div class="col-12">
				<div class="border br-8 px-2 py-3 d-middle bg-white">
					<i class="icon-back cr-pointer text-black2 f-20 mr-3" @click="$router.push({name: 'servicios.paqueteria'})" />
					<div class="w-100">
						<!-- title + state + options -->
						<div class="d-middle w-100 mb-2">
							<p class="f-18 fr-bold text-black2">{{ servicio.nombre }}</p>
							<div class="ml-auto d-middle">
								<div :class="`${servicio.estado == 1 ? 'state-active' : 'state-inactive'} rounded-pill wm-118px wM-132px h-22px f-14 d-middle-center px-2`">
									Servicio {{ servicio.estado == 1 ? 'activo' : 'inactivo' }}
								</div>
								<el-popover placement="bottom-end" width="170" trigger="hover" popper-class="p-2 br-8">
									<div class="item-pop w-146px br-4 f-13 fr-light h-32px cr-pointer d-middle px-2" @click="openModalEditService">
										Editar
									</div>
									<div class="item-pop w-146px br-4 f-13 fr-light h-32px cr-pointer d-middle px-2" @click="handleChangeState(servicio.estado)">
										{{ servicio.estado == 1 ? 'Inactivar' : 'Activar' }}
									</div>
									<div class="item-pop w-146px br-4 f-13 fr-light h-32px cr-pointer d-middle px-2" @click="openModalDeleteService">
										Eliminar
									</div>
									<i slot="reference" class="icon-dots-vertical cr-pointer ml-2" />
								</el-popover>
							</div>
						</div>
						<!-- description -->
						<p class="f-14 text-grey lh-18 mb-3"> {{ servicio.descripcion }} </p>
						<!-- cedis list -->
						<div class="d-middle h-38px" style="gap: 0.75rem">
							<div v-for="(data,index) in servicio.cedis" :key="index" :class="`${data.estado == null ? 'op-05' : ''} d-middle br-8 border p-1 w-113px`" v-if ="data.id_paqueteria" >
									<img :src="data.img" alt="" class="wh-28 bg-dark mr-2 br-5">
									<p class="tres-puntos f-15">{{ data.nombre }}</p>
							</div>
							<el-tooltip effect="light" content="Editar CEDIS">
								<div class="btn-options wh-38 d-middle-center cr-pointer" @click="openModalEditCedis">
									<i class="icon-cedis f-21" />
								</div>
							</el-tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				 <el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="Pedidos activos" name="first">
						<table-active-orders />
					</el-tab-pane>
					<el-tab-pane label="Historial" name="second">
						<table-historial />
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<modal-edit-service ref="refModalEditService" :data="servicio" @update="updateDetail" />
		<modal-editar-cedis ref="refModalEditarCedis" @update="getDetalle" />
		<modal-change-state ref="refOpenModalChangeState" :servicio="servicio" @update="changeState" />
		<modal-eliminar ref="refDeleteService" titulo="Eliminar servicio de paquetería" mensaje="¿Desea eliminar este servicio?" @eliminar="handleDeleteService" />
	</section>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	components: {
		modalEditService: () => import('./partials/modalEditService'),
		modalEditarCedis: () => import('./partials/modalEditarCedis'),
		modalChangeState: () => import('./partials/modalChangeState'),
		tableActiveOrders: () => import('./tableActivesOrders.vue'),
		tableHistorial: () => import('./tableHistorial.vue'),
	},
	data() {
		return {
			idServicio: parseInt(this.$route.params.id),
			activeName: 'first',
			servicio : {}
		}
	},
	mounted() {
		this.getDetalle();
	},
	methods: {
		openModalEditService(){
			this.$refs.refModalEditService.toggle()
		},
		handleChangeState(){
			this.$refs.refOpenModalChangeState.toggle(this.servicio.estado)
		},
		openModalEditCedis(){
			this.$refs.refModalEditarCedis.toggle(this.servicio.cedis)
		},
		openModalDeleteService(){
			this.$refs.refDeleteService.toggle()
		},
		async handleDeleteService(){
			try {
				const { data } = await Servicio.deleteServicio(this.idServicio)
				this.notificacion('Éxito', data.mensaje, "success")
				this.$refs.refDeleteService.toggle()
				this.$router.push({name:'servicios.paqueteria'})
			} catch (error) {
				return this.error_catch(error)
			}
		},
		handleClick(tab, event) {
        	console.log(tab, event);
      	},
		updateDetail(item){
			this.servicio.nombre = item.nombre;
			this.servicio.descripcion = item.descripcion;
			this.servicio.token_api = item.token;
		},
		changeState(item) {
			this.servicio.estado = item;
		},
		async getDetalle(){
			try {
				const { data } = await Servicio.getDetalle(this.idServicio)
				this.servicio = data.data;
			} catch (error) {
				return this.error_catch(error);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sec-servicios-detalles{
	.state{
		&-active{
			background-color: #29D88430;
			color: #29D884;
		}
		&-inactive{
			background-color: #DFE4E8;
			color: #637381;
		}
	}
}
</style>